<template>
  <div v-if="$store.getters.UserController.authorized(['admin','staff','tester'])">
    <v-btn text x-small @click="getFlexSearch">getFlexSearch</v-btn>
  </div>
</template>

<script>
import Flexsearch from 'flexsearch'
export default {
    created: function(){
        this.init()
    },
    data: function(){
        return {
            documents: {
                faq: null
            },
            records: null
        }
    },
    methods: {
        init: async function(){
            await this.getFlexSearch() 
            let self = this
            setTimeout(() => {
                self.initFlexSearch()   
                self.$store.commit('SearchController',self)            
            }, 0);
        },
        getFlexSearch: async function(){
            let response = {
                data: {
                    faq: [
                        {
                            id: 1,
                            topics: [],
                            question: {
                                'en-ca': this.labels['how do i register'].question._text['en-ca'],
                                'fr-ca': this.labels['how do i register'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['how do i register'].answer._text['en-ca'],
                                'fr-ca': this.labels['how do i register'].answer._text['fr-ca'],
                            }    
                        },
                        {
                            id: 2,
                            topics: [],
                            question: {
                                'en-ca': this.labels['forgot password'].question._text['en-ca'],
                                'fr-ca': this.labels['forgot password'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['forgot password'].answer._text['en-ca'],
                                'fr-ca': this.labels['forgot password'].answer._text['fr-ca'],
                            }    
                        },

                        {
                            id: 3,
                            topics: [],
                            question: {
                                'en-ca': this.labels['change password'].question._text['en-ca'],
                                'fr-ca': this.labels['change password'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['change password'].answer._text['en-ca'],
                                'fr-ca': this.labels['change password'].answer._text['fr-ca'],
                            }    
                        },

                        {
                            id: 4,
                            topics: [],
                            question: {
                                'en-ca': this.labels['comm preference'].question._text['en-ca'],
                                'fr-ca': this.labels['comm preference'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['comm preference'].answer._text['en-ca'],
                                'fr-ca': this.labels['comm preference'].answer._text['fr-ca'],
                            }    
                        },


                        {
                            id: 5,
                            topics: [],
                            question: {
                                'en-ca': this.labels['language preference'].question._text['en-ca'],
                                'fr-ca': this.labels['language preference'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['language preference'].answer._text['en-ca'],
                                'fr-ca': this.labels['language preference'].answer._text['fr-ca'],
                            }     
                        },
                        {
                            id: 6,
                            topics: [],
                            question: {
                                'en-ca': this.labels['earn credits'].question._text['en-ca'],
                                'fr-ca': this.labels['earn credits'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['earn credits'].answer._text['en-ca'],
                                'fr-ca': this.labels['earn credits'].answer._text['fr-ca'],
                            }     
                        },
                                                {
                            id: 7,
                            topics: [],
                            question: {
                                'en-ca': this.labels['how many credits'].question._text['en-ca'],
                                'fr-ca': this.labels['how many credits'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['how many credits'].answer._text['en-ca'],
                                'fr-ca': this.labels['how many credits'].answer._text['fr-ca'],
                            }     
                        },
                                                {
                            id: 8,
                            topics: [],
                            question: {
                                'en-ca': this.labels['credits earned so far'].question._text['en-ca'],
                                'fr-ca': this.labels['credits earned so far'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['credits earned so far'].answer._text['en-ca'],
                                'fr-ca': this.labels['credits earned so far'].answer._text['fr-ca'],
                            }     
                        },
                                                {
                            id: 9,
                            topics: [],
                            question: {
                                'en-ca': this.labels['supported browser'].question._text['en-ca'],
                                'fr-ca': this.labels['supported browser'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['supported browser'].answer._text['en-ca'],
                                'fr-ca': this.labels['supported browser'].answer._text['fr-ca'],
                            }     
                        },
                                                {
                            id: 10,
                            topics: [],
                            question: {
                                'en-ca': this.labels['provide feedback'].question._text['en-ca'],
                                'fr-ca': this.labels['provide feedback'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['provide feedback'].answer._text['en-ca'],
                                'fr-ca': this.labels['provide feedback'].answer._text['fr-ca'],
                            }     
                        },
                                                {
                            id: 11,
                            topics: [],
                            question: {
                                'en-ca': this.labels['suggest topic area'].question._text['en-ca'],
                                'fr-ca': this.labels['suggest topic area'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['suggest topic area'].answer._text['en-ca'],
                                'fr-ca': this.labels['suggest topic area'].answer._text['fr-ca'],
                            }     
                        },
                                                {
                            id: 12,
                            topics: [],
                            question: {
                                'en-ca': this.labels['how to share'].question._text['en-ca'],
                                'fr-ca': this.labels['how to share'].question._text['fr-ca'],
                            },
                            answer: {
                                'en-ca': this.labels['how to share'].answer._text['en-ca'],
                                'fr-ca': this.labels['how to share'].answer._text['fr-ca'],
                            }     
                        },

                    ]
                }
            }//await this.sendRequest('get','/api/getFlexSearch')
            let records = response.data
            this.records = records
        },
        initFlexSearch: function(){
            const { Document } = Flexsearch
            let language = this.language
            let records = this.records
            function stripHtml(html){
                let tmp = document.createElement("DIV");
                tmp.innerHTML = html && html.length > 0 ? html.replace(/<img[^>]*>/g,"") : html;
                return tmp.textContent || tmp.innerText || "";
            }
            const faqDocument = new Document({
                document: {
                    id: "id",
                    index: ["topics","question","answer"]
                }
            })
                for(let i=0; i<records.faq.length; i++){
                    let record = records.faq[i]
                    let document = {
                            id: record.id,
                            topics: record.topics,
                            question: stripHtml(record.question[language]),
                            answer: stripHtml(record.answer[language])
                        }
                    faqDocument.add(document)
                }

            this.documents.faq = faqDocument
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.LanguageController.language
        },
        debug: function(){
            return this.$store.getters.debug
        },
        labels(){
            return this.$store.getters.LabelController.labels.faq
        }
    },
    watch: {
        language: function(){
            this.initFlexSearch()
        }
    }
}
</script>

<style>

</style>