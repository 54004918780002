module.exports = function(component){
    this.rules = {
        checked(v){
            return v===true
        },
        length(v, num){
            return v && v.length==num
        },
        min_length(v, num){
            return v && v.length>=num
        },
        answered(v){
            if(!v || v.length==0){
                return false
            }
            return true
        },
        validEmail(email){
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!(email && re.test(email))){
              return false
            }
            return true
        },
        strongPassword(v){
            function isStrongPwd(password) {
          
                var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            
                var lowercase = "abcdefghijklmnopqrstuvwxyz";
            
                var digits = "0123456789";
            
                var splChars ="!@#$%&*()-";
            
                var ucaseFlag = contains(password, uppercase);
            
                var lcaseFlag = contains(password, lowercase);
            
                var digitsFlag = contains(password, digits);
            
                var splCharsFlag = contains(password, splChars);
      
                var lengthFlag = password.length>=6;
      
                return (lengthFlag && ucaseFlag && lcaseFlag && digitsFlag && splCharsFlag)
            
                function contains(password, allowedChars) {
              
                    for (var i = 0; i < password.length; i++) {
                
                            var char = password.charAt(i);
                
                            if (allowedChars.indexOf(char) >= 0) { return true; }
                
                        }
                
                    return false;
                }        
            }
    
            
            return v && isStrongPwd(v)
        },
        isLegitPostalCode(v){
            let uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
            let lowercase = "abcdefghijklmnopqrstuvwxyz"
            let digits = "0123456789"
            let firstAlpha = uppercase.includes(v[0]) || lowercase.includes(v[0])
            let secondDigit = digits.includes(v[1])
            let thirdAlpha = uppercase.includes(v[2]) || lowercase.includes(v[2])
            
            return v && firstAlpha && secondDigit && thirdAlpha
        }        
    }
}