<template>
    <v-container fluid class="testimonials">        
        <v-row justify="center" class="black--text">
            <template v-if="$vuetify.breakpoint.width>=1200">
            <div class="pink-wrapper" :style="{'background-image': `url('${background.pink}')`}">
                        <div class="content white--text" style="padding: 15px 41px 15px 25px;">
                            <h1>
                                <span v-if="$store.getters.LanguageController.language==='fr-ca'">1 238</span>
                                <span v-else>1,238</span>
                            </h1>
                            <Str index="home.testimonials.stats.registered"/>
                        </div>
            </div>
            <div class="yellow-wrapper" :style="{'background-image': `url('${background.yellow}')`}">
                        <div class="content" style="padding: 15px 3px 15px 102px;">
                            <h1>
                                <span v-if="$store.getters.LanguageController.language==='fr-ca'">1 370</span>
                                <span v-else>1,370</span>
                            </h1>
                            <Str index="home.testimonials.stats.credits"/>
                        </div>            
            </div>
            <div class="green-wrapper" :style="{'background-image': `url('${background.green}')`}">
                        <div class="content" style="padding: 15px 41px 15px 25px;">
                            <h1>97%</h1>
                            <Str index="home.testimonials.stats.survey.helped"/>
                        </div>            
            </div>
            <div class="blue-wrapper" :style="{'background-image': `url('${background.blue}')`}">
                        <div class="content" style="padding: 15px 3px 15px 90px;">
                            <h1>100%</h1>
                            <Str index="home.testimonials.stats.survey.recommend"/>
                        </div>                
            </div>
            </template>
            <template v-if="$vuetify.breakpoint.width<1200">
                <v-row justify="center" style="width:100%;">
                <div class="pink-wrapper" :style="{'background-image': `url('${background.pink}')`}">
                            <div class="content white--text" style="padding: 15px 41px 15px 25px;">
                                <h1>
                                    <span v-if="$store.getters.LanguageController.language==='fr-ca'">1 238</span>
                                    <span v-else>1,238</span>
                                </h1>
                                <Str index="home.testimonials.stats.registered"/>
                            </div>
                </div>
                <div class="yellow-wrapper" :style="{'background-image': `url('${background.yellow}')`}">
                            <div class="content" style="padding: 15px 3px 15px 102px;">
                                <h1>
                                    <span v-if="$store.getters.LanguageController.language==='fr-ca'">1 370</span>
                                    <span v-else>1,370</span>
                                </h1>
                                <Str index="home.testimonials.stats.credits"/>
                            </div>            
                </div>
                </v-row>
                <v-row justify="center" style="width:100%;">
                <div class="green-wrapper" :style="{'background-image': `url('${background.green}')`}">
                            <div class="content" style="padding: 15px 41px 15px 25px;">
                                <h1>97%</h1>
                                <Str index="home.testimonials.stats.survey.helped"/>
                            </div>            
                </div>
                <div class="blue-wrapper" :style="{'background-image': `url('${background.blue}')`}">
                            <div class="content" style="padding: 15px 3px 15px 90px;">
                                <h1>100%</h1>
                                <Str index="home.testimonials.stats.survey.recommend"/>
                            </div>                
                </div>
                </v-row>
            </template>
        </v-row>
        <v-row justify="center" align="center" id="testmonials-cta">
            <img :src="image.icons_testmonials" class="icons-testmonials-cta"/>
            <v-btn v-bind="size" tile outlined class="white--text" @click="scrollTo('testimonials-section')" style="height:52px !important;">
                &nbsp;&nbsp;
                <template v-if="$vuetify.breakpoint.smAndDown">
                    <p><Str index="home.testimonials.tagline.part1"/></p>
                    <p><Str index="home.testimonials.tagline.part2"/><v-icon color="white">mdi-menu-right</v-icon></p>
                </template>
                <template v-else>
                    <Str index="home.testimonials.tagline"/>
                    <v-icon color="white">mdi-menu-right</v-icon>
                </template>
            </v-btn>
        </v-row>                            
    </v-container>
</template>

<script>
import pink from './assets/trap-pink.svg'
import yellow from './assets/trap-yellow.svg'
import green from './assets/trap-green.svg'
import blue from './assets/trap-blue.svg'
import IconsTestmonials from './assets/icons-testmonials.png'
export default {
    methods: {
        scrollTo( targetElemId ){
            return document.querySelector('#'+targetElemId).scrollIntoView({behavior: 'smooth'});
        },        
    },
    computed: {
        size () {
            const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
            return size ? { [size]: true } : {}
        },
        image(){
            return {
                icons_testmonials: IconsTestmonials
            }
        },
        background(){
            return {
                pink,
                yellow,
                green,
                blue
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.testimonials{
    background-color: $gray;
    color: white;
    font-weight: bold
}
.content{
    padding: 15px 5px 15px 25px;
    
    h1 {
        font-size: 48px;
    }
}

.icons-testmonials-cta {
    left: 41px;
    position: relative;
    top: -8px;
    z-index: 100;
}

::v-deep .v-btn__content {
    display: block !important;
    
    p {
        margin: 0;
    }
}

.pink-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 100;
    left: 96px;
    top: -7px;
}
.yellow-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 75;
    top: -31px;
}
.green-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 50;
    left: -10px;
    top: -6px;
}
.blue-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 25;
    top: -31px;
    left: -93px;
}
#testmonials-cta {
    position: initial;
}

@media only screen and (max-width: 1199px) {
.pink-wrapper {
    top: 37px;
}
.yellow-wrapper {
    top: 20px;
}
.icons-testmonials-cta {
    left: 27px;
}
}

@media only screen and (max-width: 575px) {
.pink-wrapper {
    z-index: 25;
    left: 0;
}
.yellow-wrapper {
    z-index: 50;
    left: 0;
    top: -91px;
}
.green-wrapper {
    z-index: 75;
    left:-8px;
    top: -250px;
}
.blue-wrapper {
    z-index: 100;
    left: 0;
    top: -336px;
}
.testimonials {
    height: 906px;
}
#testmonials-cta {
    position: relative;
    top:-345px;
    
    .v-btn:not(.v-btn--round).v-size--small {
        padding-left: 0;
        padding-right: 0;
    }
    
    .icons-testmonials-cta {
        width: 50px;
        left: 14px;
    }
}
}
</style>