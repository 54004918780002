<template>
    <div v-if="$store.getters.UserController.authorized(['admin','staff','tester'])">
        <v-btn x-small @click="getQuestionSets">getQuestionSets</v-btn>
    </div>
</template>

<script>
export default {
    async created(){
        await this.init()
        this.$store.commit('QuestionSetController',this)
    },
    data(){
        return {
            initialized: false,
            busy: false,
            questionSets: undefined,
            submissions: undefined,
            submissionTimer: null
        }
    },
    methods: {
        async init(){
            this.initialized = false
            this.questionSets = undefined
            this.submissions = undefined

            if(this.authenticated){
                let response = await this.getQuestionSets()
                this.questionSets = response.data.questionSets
                let submissions = response.data.submissions
                for(let key in submissions){
                    this.$set(this.submissions,key,submissions[key])
                }
            }
            this.initialized = true
        },
        async getQuestionSets(){
            let ratingChoices = [
                {
                    id: 1,
                    text: {
                        'en-ca': 'Strongly Agree',
                        'fr-ca':null
                    },
                    value: 'Strongly Agree'
                },
                {
                    id: 2,
                    text: {
                        'en-ca': 'Agree',
                        'fr-ca':null
                    },
                    value: 'Agree'
                },
                {
                    id: 3,
                    text: {
                        'en-ca': 'Neutral',
                        'fr-ca':null
                    },
                    value: 'Neutral'
                },
                {
                    id: 4,
                    text: {
                        'en-ca': 'Disagree',
                        'fr-ca':null
                    },
                    value: 'Disagree'
                },
                {
                    id: 5,
                    text: {
                        'en-ca': 'Strongly Disagree',
                        'fr-ca':null
                    },
                    value: 'Strongly Disagree'
                }
            ]
            
            function ratingTableTemplate({id, text={'en-ca':null, 'fr-ca': null}, ratingChoices, rows=[], required=true}){
                function ratingChoiceTemplate({id, text={'en-ca':null, 'fr-ca': null}, value}){
                    return {
                        id,
                        text,
                        value
                    }
                }
                let _ratingChoices = []
                for (let index = 0; index < ratingChoices.length; index++) {
                    _ratingChoices.push(ratingChoiceTemplate( ratingChoices[index]))
                }

                function rowTemplate({id, text={'en-ca':null, 'fr-ca': null}}){
                    return {
                        id,
                        text
                    }
                }
                let _rows = []
                for (let index = 0; index < rows.length; index++) {
                    _rows.push(rowTemplate( rows[index]))                    
                }

                return {
                    id,
                    type: 'RatingTable',
                    required,
                    ratingChoices: _ratingChoices,
                    text,
                    rows: _rows
                }
            }
            function radioTemplate({id, text={'en-ca':null, 'fr-ca': null}, choices=[], row=false, required=true}){
                function choiceTemplate({id, text={'en-ca':null, 'fr-ca': null}, value}){
                    return {
                        id,
                        text,
                        value
                    }
                }
                let _choices = []
                for (let index = 0; index < choices.length; index++) {
                    _choices.push(choiceTemplate( choices[index]))                    
                }
                return {
                    id,
                    type: 'Radio',
                    required,
                    row,
                    choices: _choices,
                    text
                }
            }
            function textareaTemplate({id, text={'en-ca':null, 'fr-ca': null}, label={'en-ca':null, 'fr-ca': null}, required=true}){
                return {
                    id,
                    type: 'Textarea',
                    required,
                    text,
                    label
                }

            }

            // let questionSets = {
            //     1: {
            //         id: 1,
            //         description: 'Initial Pledge',
            //         routeName: 'Initial Pledge',
            //         sections: [
            //             {
            //                 id: 1,
            //                 text: {
            //                     'en-ca': 'Step 1: Formulate your practice question(s):',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     textareaTemplate({
            //                         id: 1,
            //                         type: 'textarea',
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'What was your specific question and/or learning on which you based this exercise?',
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 2,
            //                 text: {
            //                     'en-ca': 'Step 2: Describe your learning process:',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     textareaTemplate({
            //                         id: 1,
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'Describe the activity that stimulated this exercise (including where and when) and the kind of information obtained from it:',
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 3,
            //                 text: {
            //                     'en-ca': 'Step 3: Consider the information',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     textareaTemplate({
            //                         id: 1,
            //                         required: true,
            //                         text: {
            //                             'en-ca': '* What is your assessment of the quality of the information you reviewed? Describe its validity (ie, is it based on appropriate scientific evidence?) and relevance (ie, is it applicable to your patients in your community?)',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id: 2,
            //                         required: true,
            //                         text: {
            //                             'en-ca': '* What approach or tools did you use to come to this conclusion?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                 ]
            //             },
            //             {
            //                 id: 4,
            //                 text: {
            //                     'en-ca': 'Step 4: Make a decision about your practice',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     textareaTemplate({
            //                         id:1, 
            //                         required: true,
            //                         text: {
            //                             'en-ca': '* Based on what you learned, what decisions have you made about your practice and/or work?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id:2, 
            //                         required: true,
            //                         text: {
            //                             'en-ca': '* What must you do to integrate these decisions into your practice? As your Commitment to Act, please identify three changes that you will make in your practice based on your learning experience with i-ACT in Obesity<sup>TM</sup>',
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             }
            //         ]
            //     },
            //     2: {
            //         id: 2,
            //         description: 'Follow Up',
            //         routeName: 'Follow Up',
            //         sections: [
            //             {
            //                 id: 1,
            //                 text: {
            //                     'en-ca': 'Educational Objectives: The program met the stated objectives',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     ratingTableTemplate({
            //                         id: 1,
            //                         type: 'ratingTable',
            //                         required: true,
            //                         ratingChoices,
            //                         text: {
            //                             'en-ca': 'Objective:',
            //                             'fr-ca': null
            //                         },
            //                         rows: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Improve understanding of the Science of Obesity through discussion on the peripheral controls of energy balance and the multifactorial etiology of disease',
            //                                     'fr-ca':null
            //                                 }
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'Create momentum to implement new patient management approaches by solidifying weight management as a chronic disease, requiring long-term maintenance',
            //                                     'fr-ca':null
            //                                 }
            //                             },
            //                             {
            //                                 id: 3,
            //                                 text: {
            //                                     'en-ca': 'Further advance the level of care for patients living with obesity, through examination of the latest clinical treatment options.',
            //                                     'fr-ca':null
            //                                 }
            //                             }
            //                         ]
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 2,
            //                 text: {
            //                     'en-ca': 'Program content and delivery',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     ratingTableTemplate({
            //                         id: 1,
            //                         required: true,
            //                         ratingChoices,
            //                         text: {
            //                             'en-ca': 'The program content:',
            //                             'fr-ca': null
            //                         },
            //                         rows: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Was relevant to family medicine',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'Met my expectations',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 3,
            //                                 text: {
            //                                     'en-ca': 'Was well organized',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 4,
            //                                 text: {
            //                                     'en-ca': 'Disclosure of potential conflicts of interest was clearly communicated',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 5,
            //                                 text: {
            //                                     'en-ca': 'There were adequate opportunities to interact with my peers',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 6,
            //                                 text: {
            //                                     'en-ca': 'I will use the information I learned in my practice',
            //                                     'fr-ca': null
            //                                 }
            //                             }
            //                         ]
            //                     }),
            //                     radioTemplate({
            //                         id: 2,
            //                         required: true,
            //                         row: true,
            //                         choices: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Yes',
            //                                     'fr-ca': 'Oui',
            //                                 },
            //                                 value: 'Yes'
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'No',
            //                                     'fr-ca': 'Non',
            //                                 },
            //                                 value: 'No'
            //                             }
            //                         ],
            //                         text: {
            //                             'en-ca': 'Did you perceive any degree of bias in any part of the program?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id: 3,
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'What was the most effective part of the program? Why?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id: 4,
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'Describe two ways in which you will change your practice as a result of attending this program:',
            //                             'fr-ca': null
            //                         },
            //                         label: {
            //                             'en-ca': '1.',
            //                             'fr-ca': '1.'
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id: 5,
            //                         required: true,
            //                         label: {
            //                             'en-ca': '2.',
            //                             'fr-ca': '2.'
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id:6, 
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'What was the least effective part of this program? Why?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id:7, 
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'Please list any topics you would like to see addressed in future programs:',
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 3,
            //                 text: {
            //                     'en-ca': 'For programs delivered in the province of Québec',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     radioTemplate({
            //                         id: 1,
            //                         required: true,
            //                         row: true,
            //                         choices: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Yes',
            //                                     'fr-ca': 'Oui',
            //                                 },
            //                                 value: 'Yes'
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'No',
            //                                     'fr-ca': 'Non',
            //                                 },
            //                                 value: 'No'
            //                             }
            //                         ],
            //                         text: {
            //                             'en-ca': 'Did the activity respect the CQDPCM Codes of Ethics (https://cqdpcm.ca/)?',
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 4,
            //                 text: {
            //                     'en-ca': 'General comments',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     textareaTemplate({
            //                         id: 1,
            //                         required: false,
            //                         text: {
            //                             'en-ca': null,
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             }
            //         ]
            //     },
            //     3: {
            //         id: 3,
            //         description: 'Evaluation',
            //         routeName: 'Evaluation',
            //         sections: [
            //             {
            //                 id: 1,
            //                 text: {
            //                     'en-ca': 'Educational Objectives: The program met the stated objectives',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     ratingTableTemplate({
            //                         id: 1,
            //                         type: 'ratingTable',
            //                         required: true,
            //                         ratingChoices,
            //                         text: {
            //                             'en-ca': 'Objective:',
            //                             'fr-ca': null
            //                         },
            //                         rows: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Improve understanding of the Science of Obesity through discussion on the peripheral controls of energy balance and the multifactorial etiology of disease',
            //                                     'fr-ca':null
            //                                 }
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'Create momentum to implement new patient management approaches by solidifying weight management as a chronic disease, requiring long-term maintenance',
            //                                     'fr-ca':null
            //                                 }
            //                             },
            //                             {
            //                                 id: 3,
            //                                 text: {
            //                                     'en-ca': 'Further advance the level of care for patients living with obesity, through examination of the latest clinical treatment options.',
            //                                     'fr-ca':null
            //                                 }
            //                             }
            //                         ]
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 2,
            //                 text: {
            //                     'en-ca': 'Program content and delivery',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     ratingTableTemplate({
            //                         id: 1,
            //                         required: true,
            //                         ratingChoices,
            //                         text: {
            //                             'en-ca': 'The program content:',
            //                             'fr-ca': null
            //                         },
            //                         rows: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Was relevant to family medicine',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'Met my expectations',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 3,
            //                                 text: {
            //                                     'en-ca': 'Was well organized',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 4,
            //                                 text: {
            //                                     'en-ca': 'Disclosure of potential conflicts of interest was clearly communicated',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 5,
            //                                 text: {
            //                                     'en-ca': 'There were adequate opportunities to interact with my peers',
            //                                     'fr-ca': null
            //                                 }
            //                             },
            //                             {
            //                                 id: 6,
            //                                 text: {
            //                                     'en-ca': 'I will use the information I learned in my practice',
            //                                     'fr-ca': null
            //                                 }
            //                             }
            //                         ]
            //                     }),
            //                     radioTemplate({
            //                         id: 2,
            //                         required: true,
            //                         row: true,
            //                         choices: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Yes',
            //                                     'fr-ca': 'Oui',
            //                                 },
            //                                 value: 'Yes'
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'No',
            //                                     'fr-ca': 'Non',
            //                                 },
            //                                 value: 'No'
            //                             }
            //                         ],
            //                         text: {
            //                             'en-ca': 'Did you perceive any degree of bias in any part of the program?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id: 3,
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'What was the most effective part of the program? Why?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id: 4,
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'Describe two ways in which you will change your practice as a result of attending this program:',
            //                             'fr-ca': null
            //                         },
            //                         label: {
            //                             'en-ca': '1.',
            //                             'fr-ca': '1.'
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id: 5,
            //                         required: true,
            //                         label: {
            //                             'en-ca': '2.',
            //                             'fr-ca': '2.'
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id:6, 
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'What was the least effective part of this program? Why?',
            //                             'fr-ca': null
            //                         }
            //                     }),
            //                     textareaTemplate({
            //                         id:7, 
            //                         required: true,
            //                         text: {
            //                             'en-ca': 'Please list any topics you would like to see addressed in future programs:',
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 3,
            //                 text: {
            //                     'en-ca': 'For programs delivered in the province of Québec',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     radioTemplate({
            //                         id: 1,
            //                         required: true,
            //                         row: true,
            //                         choices: [
            //                             {
            //                                 id: 1,
            //                                 text: {
            //                                     'en-ca': 'Yes',
            //                                     'fr-ca': 'Oui',
            //                                 },
            //                                 value: 'Yes'
            //                             },
            //                             {
            //                                 id: 2,
            //                                 text: {
            //                                     'en-ca': 'No',
            //                                     'fr-ca': 'Non',
            //                                 },
            //                                 value: 'No'
            //                             }
            //                         ],
            //                         text: {
            //                             'en-ca': 'Did the activity respect the CQDPCM Codes of Ethics (https://cqdpcm.ca/)?',
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             },
            //             {
            //                 id: 4,
            //                 text: {
            //                     'en-ca': 'General comments',
            //                     'fr-ca': null
            //                 },
            //                 subtext: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 footer: {
            //                     'en-ca': null,
            //                     'fr-ca': null
            //                 },
            //                 questions: [
            //                     textareaTemplate({
            //                         id: 1,
            //                         required: false,
            //                         text: {
            //                             'en-ca': null,
            //                             'fr-ca': null
            //                         }
            //                     })
            //                 ]
            //             }
            //         ]
            //     }
            // }

            let response
            let questionSets = {}
            try {
                response = await this.sendRequest('get','/api/questionSet')
                for(let i=0; i<response.data.questionSet.length; i++){
                    let record = response.data.questionSet[i]
                    questionSets[record.id] = record
                }
            } catch (error) {
                
            }

            let submissions = await this.getSubmissions()

            return {
                data: {questionSets, submissions}
            }
        },
        gotoQuestionSet(questionSet){
            console.log('gotoQuestionSet',questionSet)
            this.$router.push({name:questionSet.routeName})
        },
        async submit(questionSet, form){
            this.busy = true
            let self = this
            clearTimeout(self.submissionTimer)
            self.submissionTimer = setTimeout(async () => {
                try {
                    let response = await self.sendRequest('put','/api/answers',{questionSetId:questionSet.id,answers: form, language:self.language})
                    console.log('submit',response)
                } catch (error) {
                    console.error('submit',error)
                }
                setTimeout(async () => {
                    await self.getSubmissions()
                    self.busy = false
                }, 1000);
            }, 200);
        },
        async getSubmissions(questionSetId){
            let response
            try {
                let endpoint = '/api/answers'
                if(questionSetId){
                    endpoint+=`/${questionSetId}`
                }
                response = await this.sendRequest('get',endpoint)
                let submissions = {}
                for(let i=0; i<response.data.submissions.length; i++){
                    let record = response.data.submissions[i]
                    submissions[record.questionSetId] = record
                }
                this.submissions = submissions
                return submissions
            } catch (error) {
                console.error('getSubmissions',error)
            }
        },
        async deleteSubmission(questionSetId){
            let record = this.submissions[questionSetId]
            if(record){
                try {
                    let response = await this.sendRequest('delete','/api/answers',{recordId:record.id, questionSetId})
                    await this.getSubmissions()
                    return response
                } catch (error) {
                    console.error(error)
                }
            }
            // return new Promise((resolve, reject)=>{
            //     let self = this
            //     self.busy = true
            //     setTimeout(() => {
            //         let questionSetSubmissions = self.cache.storage.local.get('questionSetSubmissions') ? self.cache.storage.local.get('questionSetSubmissions') : {}
            //         delete questionSetSubmissions[questionSet_id]
            //         self.cache.storage.local.set('questionSetSubmissions', questionSetSubmissions)
            //         self.getSubmissions()
            //         self.busy = false
            //         resolve(questionSetSubmissions)                
            //     }, 1000);

            // })
        }
    },
    computed: {
        language(){
            return this.$store.getters.LanguageController.language
        },
        authenticated(){
            return this.$store.getters.UserController.authenticated
        }   
    },
    watch: {
        authenticated(){
            this.init()
        }
    }
}
</script>

<style>

</style>