<template>
  <div>
    <template v-for="(branch, branchKey) in tree">
        <SubSelector v-if="branchKey!='_text'" :tree="branch" :key="`sub-${trace}-${branchKey}`" @editLabel="signalEdit" @deleteLabel="signalDelete" :trace="`${trace}.${branchKey}`"/>
        <v-list v-else :key="`text-${trace}-${branchKey}`">
           <v-list-item>
            <v-list-item-action>
                <div>
                    <v-btn icon small @click="signalEdit(`${trace}.${branchKey}`)">
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>

                    <v-btn icon small @click="signalDelete(`${trace}.${branchKey}`)">
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </div>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    {{ trace }}
                </v-list-item-title>

                <v-list dense>
                    <v-list-item v-for="(item, itemKey) in branch" :key="`item-${trace}-${branchKey}-${itemKey}`">

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ itemKey }}
                            </v-list-item-title>
                            <span v-html="item"/>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>                
            </v-list-item-content>
           </v-list-item>
        </v-list>
    </template>
  </div>
</template>

<script>
import SubSelector from './Selector.vue'
export default {
    name: 'SubSelector',
    components: {
      SubSelector
    },
    props: {
        trace: {
            type: String,
            required: true
        },
        tree: {
            type: Object,
            required: true
        }
    },
    methods: {
        signalEdit(data){
            this.$emit('editLabel',data)
        },
        signalDelete(data){
            this.$emit('deleteLabel',data)
        }
    }
}
</script>

<style>

</style>