<template>
  <v-carousel v-model="model" 
      :continuous="false"
      :cycle="true"
      :show-arrows-on-hover="true"
      :show-arrows="true"
      height="auto"
      hide-delimiter-background
      continuous
      >
    <v-carousel-item
      v-for="(slide,i) in content"
      :key="`slide-${i}`"
      style="height: 220px;"
    >
    <!-- class="fill-height" -->
    <!-- justify="center" -->
    <!-- align="center" -->
    <Str :index="slide"/>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
    props: {
        content: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            model: 0
        }
    }
}
</script>

<style>
.v-window__prev, .v-window__next {
  top: 150px;
}
</style>