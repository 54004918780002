<template>
  <v-card class="expert" tile elevation="7"  :height="$vuetify.breakpoint.width<572 ? 'auto' : '583'">
    <div class="picture" :style="{'background-image':`URL('${expert.image}')`}"> 
        <!-- <div class="triangle"/> -->
    </div>
    <div class="expertInfo">
        <div class="name" v-html="expert.name"/>
        <div class="credentials" v-html="expert.credentials[language]"/>        
    </div>
    <hr>
    <v-card-text class="details">
        <div v-html="expert.details[language]"/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        expert: {
            type: Object,
            required: true
        }
    },
    computed: {
        language(){
            return this.$store.getters.LanguageController.language
        }
    }
}
</script>

<style lang="scss" scoped>
.triangle{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0px 0px 150px;
    border-color: transparent transparent transparent white;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.picture{
    position: relative;
    width: 100%;
    height: 243px;
    // border: 1px solid grey;
    background-size: contain;
    background-position: center;
}
.expertInfo{
    position: relative;
    margin: 12px;
    top: -10px;
}
.name{
    font-weight: bold;
}
.credentials{
    font-size: 0.8em;
}
.details{
    padding: 10px;
    font-size: 0.8em;
}
</style>

<style>
.expert ul{
    padding-left: 15px;
}
</style>