<template>
    <span v-if="controller">
        <v-menu v-if="controller.exposeLabels" :open-on-hover="controller.exposeLabels" :offset-x="false" top max-width="60">
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" v-html="output" :class="[missing ? 'missing' : '', controller.exposeLabels ? 'exposed' : '']"/> 
            </template>
            <v-btn @click="edit" x-small fab><v-icon x-small>mdi-pencil</v-icon></v-btn>
        </v-menu>
        <span v-else v-html="output" :class="missing ? 'missing' : ''"/> 
    </span>
</template>

<script>
export default {
    name: 'Str',
    props: {
        index: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: false
        }
    },
    data: function(){
        return {
            missing: false
        }
    },
    methods: {
        edit: function(){
            this.$router.push({name:'Label Admin',query: {index:this.index.split('._text')[0]}})
        }
    },
    computed: {
        controller: function(){
            return this.$store.getters.LabelController
        },
        lang: function(){
            return this.language ? this.language : this.$store.getters.LanguageController.language
        },
        output: function(){
            let controller = this.controller
            let language = this.lang
            this.missing = false
            if(controller){
                let output = controller.getLabel(this.index, language)
                if(output===undefined){
                    this.missing = true
                }
                return output ? output : this.index
            }
        }
    }
}
</script>

<style scoped>
.exposed{
    color: purple !important;
    font-style: italic;
}

.missing{
    color: red !important;
    font-style: italic;
}
</style>