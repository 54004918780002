<template>
<v-container id="hero-banner-wrapper" fluid fill-height
    :style="{
        'background-image': `url('${image.background}')`,
        'background-repeat': 'repeat-y',
        'min-height': $vuetify.breakpoint.smAndDown ? 'fit-content': '720px',
        'background-size': 'cover',
        'overflow': 'hidden'}">       
    <!--
    <VideoPlayer v-if="!$vuetify.breakpoint.smAndDown" :key="`bannerVideo_${bannerVideoKey}`" :videoId="'bannerVideo'" :source="video('banner-video')" :options="{video:{autoplay: true, muted: true, loop: true}}"/>
    -->
    <v-row align="center">
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
        <v-col>
            <h1 :class="[{'mobile-heading':$vuetify.breakpoint.smAndDown}]">
                <Str index="home.banner.title"/>
            </h1>
            <h2 :class="['black--text', $vuetify.breakpoint.lgAndUp ? 'text-h5' : $vuetify.breakpoint.mdAndUp ? 'text-h6' : 'subtitle-1']">
                <Str index="home.banner.subtitle"/>
            </h2>
            <StartButton :options="{flavor:'cta-plain'}"/>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.width<611 ? 12 : 6" class="d-flex flex-column justify-center align-center">
            <div style="max-width: 659px;">
                <template v-if="introVideo.state==null">
                    <img :src="image.video_placeholder" @click="introVideo.state='initiate'" style="cursor: pointer; width: 100%;"/>
                    <img :src="image.polygon_line" style="width: 100%;"/>
                </template>
                <div v-if="introVideo.state!==null && deviceType=='mobile'">
                    <VideoPlayer class="videoPlayer" :videoId="'intro-Video'" :source="video(introVideoSrc)" :options="{video:{autoplay: true, loop: false, controls: true, playsinline: true}}"                       
                        @player="setIntroPlayer" @playing="introVideo.state='playing'" @ended="introVideo.state=null"/>
                </div>
            </div>           
            <!--
            <VideoPlayer v-if="!$vuetify.breakpoint.smAndDown" :key="`bannerVideo_${bannerVideoKey}`" :videoId="'bannerVideo'" :source="video('banner-video')" :options="{video:{autoplay: true, muted: true, loop: true}}"/>
            -->
        </v-col>
        <v-col class="content" cols="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
        <div style="position: absolute" v-if="introVideo.state!==null && deviceType!=='mobile'">
            <VideoPlayer class="videoPlayer" :videoId="'intro-Video'" :source="video(introVideoSrc)" :options="{video:{autoplay: true, loop: false, controls: true, playsinline: true}}"                       
                @player="setIntroPlayer" @playing="introVideo.state='playing'" @ended="introVideo.state=null"/>
        </div>
    </v-row>      
</v-container>
</template>

<script>
import VideoPlayer from '@/components/Videos/lib/videoPlayer.vue'
import Background from './assets/banner-background.jpg'
import PolygonsLine from './assets/polygons-line.png'
import EnBannerVideoPlaceholder from './assets/en-banner-video-placeholder.jpg'
import FrBannerVideoPlaceholder from './assets/fr-banner-video-placeholder.jpg'
import StartButton from './StartButton.vue'
export default {
    components: {
        VideoPlayer,
        StartButton
    },
    data(){
        return {
            introVideo: {
                state: null,
                player: null
            },
            bannerVideoKey: 0,
            deviceType: null
        }
    },
    mounted() {
        this.setDeviceType();
    },
    methods: {
        setDeviceType() {
            const platform = navigator.platform.toLowerCase();
            if (/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform)) {
                this.deviceType = 'mobile';
            } else if (/mac|win|linux/i.test(platform)) {
                this.deviceType = 'desktop';
            } else if (/tablet|ipad/i.test(platform)) {
                this.deviceType = 'tablet';
            } else {
                this.deviceType = 'unknown';
            }
        },
        video(param){
            if (this.language==="fr-ca"){
                return param==="banner-video" ?  "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/FRE_1080Banner_iACT_Teaser_Video_V2.mp4"
                    : param==="intro-video" ? "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/FRE_1080_iACT_Welcome_Video_V2.mp4"
                    : null
            }
            return param==="banner-video" ?  "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/ENG_1080Banner_iACT_Teaser_Video_V2.mp4"
                    : param==="intro-video" ? "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/ENG_1080_iACT_Welcome_Video_V2.mp4"
                    : null            
        },
        toggleVideoFullScreen(){
            if (this.deviceType!=='mobile'){
                let el = document.getElementById("intro-Video");
                if (el.requestFullscreen) {
                  el.requestFullscreen();
                } else if (el.msRequestFullscreen) {
                  el.msRequestFullscreen();
                } else if (el.mozRequestFullScreen) {
                  el.mozRequestFullScreen();
                } else if (el.webkitRequestFullscreen) {
                  el.webkitRequestFullscreen();
                }

                if (document.addEventListener){
                    document.addEventListener('fullscreenchange', this.exitHandler, false)
                    document.addEventListener('mozfullscreenchange', this.exitHandler, false)
                    document.addEventListener('MSFullscreenChange', this.exitHandler, false)
                    document.addEventListener('webkitfullscreenchange', this.exitHandler, false)
                }
            }
        },
        exitHandler(){
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement){
                console.log("Exit-Full-Screen")
                this.introVideo.state = null
            }
        },
        setIntroPlayer(param){
            this.introVideo.player = param
        }
    },
    computed: {
        image(){
            let output = {
                background: Background,
                video_placeholder: this.language==="fr-ca" ? FrBannerVideoPlaceholder : EnBannerVideoPlaceholder,
                polygon_line: PolygonsLine
            }

            return output
        },
        introVideoSrc(){
            return this.introVideo.state!==null ? 'intro-video' : null
        },
        introPlayer() {
            return this.introVideo.player
        },
        language(){
            return this.$store.getters.LanguageController.language
        }
    },
    watch: {
        introPlayer(after, before){
            if (after){
                return this.toggleVideoFullScreen()
            }
        },
        language(after, before){
            this.bannerVideoKey++;
        }
    }

}
</script>

<style lang="scss" scoped>
.content{
  z-index: 5;
}

h1{
  color: $pink;
  font-weight: bold;
  font-size: 4em;
  line-height: 1em;
  margin: 15px 0px 25px 0px;
}

.mobile-heading {
    font-size: 3.3em;
    text-align: center;
    padding-top: 22px;
}

h2{
  font-size: 1em;
  line-height: 1.2em;
  margin: 15px 0px 25px 0px;
}

a{
    color: white !important;
}
a:hover{
    border-color: $midgray !important;
}
</style>