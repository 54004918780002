<template>
<div v-if="$store.getters.UserController.authorized(['admin','staff','tester'])">
    <v-btn text x-small @click="calculateCredits">calculateCredits</v-btn>
    <v-btn text x-small @click="getCertificates">getCertificates</v-btn>
    <v-btn text x-small @click="resetCertificates">resetCertificates</v-btn>
</div>
</template>

<script>
export default {
    props: {
        VideoController: {
            type: Object,
            required: true
        },
        QuestionSetController: {
            type: Object,
            required: true
        }
    },
    async created(){
        this.init()
        this.$store.commit('CreditController',this)
    },
    data: function(){
        return {
            followUp: {
                questionSetId: 2,
                creditValue: 1
            },
            initialPledge: {
                questionSetId: 1,
                creditValue: 0
            },
            initialized: false,
            queuedCredits: 0,
            usedCredits: 0,
            totalCredits: 0,
            certificates: []
        }
    },
    methods: {
        async init(){
            this.initialized = false
            this.certificates = []
            if(this.authenticated){
                await this.getCertificates()
                this.calculateCredits()
            }
            this.initialized = true
        },
        resetCredits(){
            this.queuedCredits = 0
            this.usedCredits = 0
            this.totalCredits = 0
        },
        async calculateCredits(){
            this.resetCredits()
            if(this.authenticated){
                let watched = this.VideoController.watched
                let submissions = this.QuestionSetController ? this.QuestionSetController.submissions : null
                let followUpCompleted = submissions && submissions[this.followUp.questionSetId] ? true : false
                if(followUpCompleted){
                    this.totalCredits+=this.followUp.creditValue
                    this.queuedCredits+=this.followUp.creditValue
                }
                let certificates = this.certificates
                for(let i=0; i<watched.length; i++){
                    let video = watched[i]
                    this.totalCredits+=video.credits
                    this.queuedCredits+=video.credits
                }
                for(let i=0; i<certificates.length; i++){
                    let certificate = certificates[i]
                    this.queuedCredits-=certificate.credits
                    this.usedCredits+=certificate.credits
                }
            }
        },
        async getCertificates(){
            let response
            try {
                response = await this.sendRequest('get','/api/certificate')
                this.certificates = response.data.myCertificates
                for(let i=0; i<this.certificates.length; i++){
                    this.certificates[i].date = new Date(this.certificates[i].date)
                }
            } catch (error) {
                this.certificates = []
            }
            this.calculateCredits()
        },
        async generateCertificate(){
            if(this.queuedCredits>0){
                let d = new Date()
                let record = {
                    name: this.name,
                    date: d.getTime(),
                    credits: this.queuedCredits,
                    language: this.language
                }
                // let certificates = this.cache.storage.local.get('certificates') ? this.cache.storage.local.get('certificates') : []
                // certificates.push(record)
                // this.cache.storage.local.set('certificates', certificates)
                // this.usedCredits += this.queuedCredits
                // this.queuedCredits = 0
                try {
                    let response = await this.sendRequest('post','/api/certificate',record)
                    console.log('generateCertificate',response)
                } catch (error) {
                    
                }
            }
            this.getCertificates()
        },
        async resetCertificates(){
            this.cache.storage.local.clear('certificates')
            this.getCertificates()
        },
        async deleteCertificate(certificate){
            try {
                let response = await this.sendRequest('delete','/api/certificate',{recordId:certificate.id})
                await this.getCertificates()
                return response
            } catch (error) {
                console.error(error)
            }
        }
    },
    computed:{
        maxCredits(){
            let videos = this.VideoController.videos
            let output = 0
            for(let i=0; i<videos.length; i++){
                let video = videos[i]
                output+=video.credits
            }
            return output
        },
        videos(){
            return this.VideoController.videos
        },
        submissions(){
            return this.QuestionSetController ? this.QuestionSetController.submissions : {}
        },
        language(){
            return this.$store.getters.LanguageController.language
        },
        authenticated(){
            return this.$store.getters.UserController.authenticated
        },
        name(){
            let attributes = this.$store.getters.UserController.user.attributes
            return `${attributes.given_name} ${attributes.family_name}`
        },
        _productUpdateTrigger(){

            let submissions = this.QuestionSetController ? this.QuestionSetController.submissions : null
            let pledgeCompleted = submissions && submissions[this.initialPledge.questionSetId] ? true : false
            let followUpCompleted = submissions && submissions[this.followUp.questionSetId] ? true : false

            return {
                queued_credits: this.queuedCredits,
                video_credits: this.credits.videos.earned,
                pledge_credits: followUpCompleted ? this.followUp.creditValue : 0,
                commitment_to_act: pledgeCompleted,
                commitment_to_act_fup: followUpCompleted
            }
        },
        credits(){
            let output = {
                videos: {
                    max: 0,
                    earned: 0
                },
                followUp: {
                    max: this.followUp.creditValue,
                    earned: 0
                }
            }
            let watched = this.VideoController.watched
            for(let i=0; i<watched.length; i++){
                let video = watched[i]
                output.videos.earned+=video.credits
            }
            
            let videos = this.VideoController.videos
            for(let i=0; i<videos.length; i++){
                let video = videos[i]
                output.videos.max+=video.credits
            }
            
            let submissions = this.QuestionSetController ? this.QuestionSetController.submissions : null
            let followUpCompleted = submissions && submissions[this.followUp.questionSetId] ? true : false
            if(followUpCompleted){
                output.followUp.earned+=this.followUp.creditValue
            }
                
                
            return output
        }
    },
    watch: {
        videos: {
            deep: true,
            handler(){
                this.calculateCredits()
            }
        },
        submissions: {
            deep: true,
            handler(){
                this.calculateCredits()
            }
        },
        _productUpdateTrigger: {
            deep: true,
            handler(){
                this.$store.getters.UserController.hs.product.update(this._productUpdateTrigger)
            }
        },
        authenticated(){
            this.init()
        }
    }
}
</script>

<style>

</style>