<template>
    <v-dialog :persistent="!allowClose" v-model="ui.display" width="80%">
        <v-card flat>
            <v-card-actions>
                <Str index="authentication.form.profile.title" class="font-weight-bold"/><v-spacer></v-spacer>
                <v-btn color="green" dark tile depressed @click="$store.getters.UserController.signOut" :small="$vuetify.breakpoint.width<330">
                    <v-icon left>
                        mdi-exit-to-app
                    </v-icon>
                    <Str index="site.common.sign_out"/>
                </v-btn>
            </v-card-actions>
            <v-card style="width: 95%; margin: 25px auto;">
                <v-form ref="form" v-model="validForm" v-if="ui.state=='profile'">
                    <v-container>
                        <v-row>
                            <v-col cols="6" sm="3">
                                <v-text-field :label="$store.getters.LabelController.getLabel('authentication.form.label.first_name')" v-model="form.given_name" required :rules="rules.required"/>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-text-field :label="$store.getters.LabelController.getLabel('authentication.form.label.last_name')" v-model="form.family_name" required :rules="rules.required"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-select :label="$store.getters.LabelController.getLabel('authentication.form.label.profession')" v-model="form['custom:profession']" :items="professionList" required :rules="rules.required">
                                    <template v-slot:message="{message}">
                                        <Str index="authentication.form.label.select_or_type"/>
                                    </template>                                    
                                </v-select>
                            </v-col>
                            <v-col v-if="form['custom:profession']=='physician'" cols="12" sm="3">
                                <v-select :label="$store.getters.LabelController.getLabel('authentication.form.label.specialty')" v-model="form['custom:specialty']" :items="specialtyList" required :rules="rules.required">
                                    <template v-slot:message="{message}">
                                        <Str index="authentication.form.label.select_or_type"/>
                                    </template>                                    
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-select :label="$store.getters.LabelController.getLabel('authentication.form.label.country')" :items="countries" v-model="form['custom:country']" required :rules="rules.required"/>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select :label="$store.getters.LabelController.getLabel('authentication.form.label.province_state')" item-disabled="disable" :items="states" v-model="form['custom:province']" required :rules="rules.required"/>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field id="postal-code" :label="$store.getters.LabelController.getLabel('authentication.form.label.postal_code')" v-model="form['custom:postal_code']" required :rules="rules.postal_code"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <div class="d-flex flex-column-reverse flex-sm-row">
                            <v-col>
                                <v-text-field disabled :label="$store.getters.LabelController.getLabel('authentication.form.label.email')" v-model="form.email"/>
                                <!--
                                <v-btn color="darkgray" dark small @click="ui.state='changeEmail'"><Str index='authentication.form.button.change_email'/></v-btn>
                                -->
                            </v-col>
                            <v-col>
                                <v-select :label="$store.getters.LabelController.getLabel('authentication.form.label.preferred_language')" :items="$store.getters.LanguageController.languages" v-model="form['custom:preferred_language']" required :rules="rules.required"/>
                            </v-col>
                            </div>
                            <v-col>
                                <v-btn color="darkgray" dark small @click="ui.state='changePassword'">{{$store.getters.LabelController.getLabel('authentication.form.button.change_password')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-container v-if="ui.state=='changePassword'">
                    <v-row>
                        <v-col>
                            <ChangePassword v-model="form.email" @back="ui.state='profile'" @success="ui.state='profile'"/>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container v-if="ui.state=='changeEmail'">
                    <v-row>
                        <v-col>
                            <ChangeEmail v-model="form.email" @back="ui.state='profile'" @success="ui.state='profile'"/>
                        </v-col>
                    </v-row>
                </v-container>

            </v-card>
            <v-card-actions style="border-top: 1px solid lightgray; margin: 5px;">
                <v-btn v-if="allowClose" x-large color="blue" dark tile @click="ui.display=false"><Str index="authentication.form.button.cancel"/></v-btn>
                <v-spacer></v-spacer>
                <!-- <v-btn @click="test">Test</v-btn>
                <v-spacer></v-spacer> -->
                <v-btn :disabled="!validForm" x-large color="blue" dark tile @click="save"><Str index="authentication.form.button.save"/></v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import ChangePassword from '../Authentication/ChangePassword.vue'
import ChangeEmail from '../Authentication/ChangeEmail.vue'
export default {
    name: "Profile",
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    created(){
        this.getCountries()
        this.$emit('ready', this)
        let form = JSON.stringify(this.user.attributes)
        this.form = JSON.parse(form)
        if(!this.allowClose){
            this.open()
        }
        this.formRules = new this.formRulesAPI(this)
    },
    components: {
        ChangePassword,
        ChangeEmail
    },
    data(){
        return {
            ui: {
                display: false,
                state: 'profile'
            },
            validForm: false,
            formRules: undefined,
            form: {},
            rules: {
                required: [
                    v => this.formRules.rules.answered(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field')
                ],
                checkbox: [
                    v => this.formRules.rules.checked(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
                ],
                email: [
                    v => this.formRules.rules.validEmail(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
                ],
                postal_code: [
                    v => !!v || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field'),
                    v => this.formRules.rules.min_length(v,3) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry'),
                    v => this.formRules.rules.isLegitPostalCode(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry'),
                ],
                password: [
                    v => !!v || 'authentication.form.label.required_field',
                    v => this.formRules.rules.answered(v) || 'authentication.form.label.required_field',
                    v => this.formRules.rules.strongPassword(v) || 'authentication.password_complexity'
                ],
            },
            csc: {
                countries: null,
                states: null
            }
        }
    },
    methods: {
        open(){
            this.ui.display = true
            this.ui.state = 'profile'
        },
        async test(){
            let form = this.form
            let fields = [
                'given_name',
                'family_name',
                'custom:profession',
                'custom:specialty',
                'custom:country',
                'custom:province',
                'custom:preferred_language',
                'custom:postal_code'
            ]
            let packet = {}
            for(let i=0; i<fields.length; i++){
                let field = fields[i]
                if(form[field]){
                    packet[field] = form[field]
                }                
            }
            let result = await this.$store.getters.UserController.hs.contact.transformCognitoFields(packet)
            console.log({result, packet})
        },
        async save(){
            let form = this.form
            let fields = [
                'given_name',
                'family_name',
                'custom:profession',
                'custom:specialty',
                'custom:country',
                'custom:province',
                'custom:preferred_language',
                'custom:postal_code'
            ]
            let packet = {}
            for(let i=0; i<fields.length; i++){
                let field = fields[i]
                if(form[field]){
                    packet[field] = form[field]
                }
            }
            let result = await this.$store.getters.UserController.updateAttribute(packet)
            this.ui.display = false
        },
        async getCountries(){
            let response = await this.sendRequest('get','/api/csc/getCountries')
            this.csc.countries = response.data
        },
        async getStates(){
            let countryCode = this.form['custom:country']
            let response = await this.sendRequest('get',`/api/csc/getStates/${countryCode}`)
            this.csc.states = response.data
        }
    },
    computed:{
        language(){
            return this.$store.getters.LanguageController.language
        },
        professionList() {
            let language = this.language

            // let labels = this.$store.getters.LabelController.getLabel('authentication.form.profile.profession_list')
            // return professions!==undefined ? JSON.parse(professions) : []
            
            let list = {
                'en-ca': [
                    { text: 'Nurse', value: 'nurse'},
                    { text: 'Nurse Practitioner', value: 'Nurse Practitioner'},
                    { text: 'Nutritionist', value: 'nutritionist'},
                    { text: 'Pharmacist', value: 'pharmacist'},
                    { text: 'Physician', value: 'physician'},
                    { text: 'Physician Assistant', value: 'physician_assistant'},
                    { text: 'Other', value: 'other'}
                ] ,
                'fr-ca': [
                    { text: 'Infirmièr(e)',  value: 'nurse'},
                    { text: 'Infirmière praticien(ne)', value: 'Nurse Practitioner'},
                    { text: 'Nutritionniste',  value: 'nutritionist'},
                    { text: 'Pharmacien(ne)',  value: 'pharmacist'},
                    { text: 'Médecin',  value: 'physician'},
                    { text: 'Adjoint au médecin', value: 'physician_assistant'},
                    { text: 'Autre',  value: 'other'}
                ]
            }
            return list[language]
        },
        specialtyList() {
            let language = this.language

            let list = {
                'en-ca': [
                    {
                        text: 'Anesthesia', value: 'anesthesia'
                    },
                    {
                        text: 'Bariatrics', value: 'bariatrics'
                    },
                    {
                        text: 'Cardiology', value: 'cardiology'
                    },
                    {
                        text: 'Cardiothoracic Surgery', value: 'cardiothoracic_surgery'
                    },
                    {
                        text: 'Clinical Immunology & Allergy', value: 'clin_immun_allergy'
                    },
                    {
                        text: 'Community Medicine', value: 'Community_medicine'
                    },
                    {
                        text: 'Critical Care Medicine', value: 'critical_care_medicine'
                    },
                    {
                        text: 'Dermatology', value: 'dermatology'
                    },
                    {
                        text: 'Emergency Medicine', value: 'emergency_medicine'
                    },
                    {
                        text: 'Endocrinology', value: 'endocrinology'
                    },
                    {
                        text: 'Epidemiology', value: 'epidemiology'
                    },
                    {
                        text: 'Family Medicine', value: 'family_medicine'
                    },
                    {
                        text: 'Gastroenterology', value: 'gastroenterology'
                    },
                    {
                        text: 'General Pathology', value: 'general_pathology'
                    },
                    {
                        text: 'General Practice', value: 'physician_general_practice'
                    },
                    {
                        text: 'General Surgery', value: 'general_surgery'
                    },
                    {
                        text: 'Geriatric Medicine', value: 'geriatric_medicine'
                    },
                    {
                        text: 'Gynecology', value: 'gynecology'
                    },
                    {
                        text: 'Gynecologic Oncology', value: 'gynecologic_oncology'
                    },
                    {
                        text: 'Hematological Pathology', value: 'hematological_pathology'
                    },
                    {
                        text: 'Hematology', value: 'hematology'
                    },
                    {
                        text: 'Infectious Diseases', value: 'infectious_disease'
                    },
                    {
                        text: 'Internal Medicine', value: 'internal_medicine'
                    },
                    {
                        text: 'Medical Oncology', value: 'medical_oncology'
                    },
                    {
                        text: 'Nephrology', value: 'nephrology'
                    },
                    {
                        text: 'Neurology', value: 'neurology'
                    },
                    {
                        text: 'Neuropathology', value: 'neuropathology'
                    },
                    {
                        text: 'Neurosurgery', value: 'neurosurgery'
                    },
                    {
                        text: 'Nuclear Medicine', value: 'nuclear_medicine'
                    },
                    {
                        text: 'Obstetrics & Gynecology', value: 'obstetrics_gynecology'
                    },
                    {
                        text: 'Ophthalmology', value: 'ophthalmology'
                    },
                    {
                        text: 'Orthopedic Surgery', value: 'orthopedic_surgery'
                    },
                    {
                        text: 'Other', value: 'other'
                    },
                    {
                        text: 'Otolaryngology', value: 'otolaryngology'
                    },
                    {
                        text: 'Pediatrics', value: 'pediatrics'
                    },
                    {
                        text: 'Physical Medicine & Rehabilitation', value: 'physicial_medicine_rehabilitation'
                    },
                    {
                        text: 'Plastic Surgery', value: 'plastic_surgery'
                    },
                    {
                        text: 'Psychiatry', value: 'psychiatry'
                    },
                    {
                        text: 'Radiation Oncology', value: 'radiation_oncology'
                    },
                    {
                        text: 'Radiology', value: 'radiology'
                    },
                    {
                        text: 'Respirology', value: 'respirology'
                    },
                    {
                        text: 'Rheumatology', value: 'rheumatology'
                    },
                    {
                        text: 'Sleep Specialist', value: 'sleep_specialist'
                    },
                    {
                        text: 'Urology', value: 'urology'
                    },
                ] ,
                'fr-ca': [
                    {
                        text: 'Anesthésie', value: 'anesthesia'
                    },
                    {
                        text: 'Bariatrie', value: 'bariatrics'
                    },
                    {
                        text: 'Cardiologie', value: 'cardiology'
                    },
                    {
                        text: 'Chirurgie cardiothoracique', value: 'cardiothoracic_surgery'
                    },
                    {
                        text: 'Immunologie clinique et allergie', value: 'clin_immun_allergy'
                    },
                    {
                        text: 'Médecine communautaire', value: 'Community_medicine'
                    },
                    {
                        text: 'Médecine de soins intensifs', value: 'critical_care_medicine'
                    },
                    {
                        text: 'Dermatologie', value: 'dermatology'
                    },
                    {
                        text: 'Médecine d’urgence', value: 'emergency_medicine'
                    },
                    {
                        text: 'Endocrinologie', value: 'endocrinology'
                    },
                    {
                        text: 'Épidémiologie', value: 'epidemiology'
                    },
                    {
                        text: 'Médecine familiale', value: 'family_medicine'
                    },
                    {
                        text: 'Gastroentérologie', value: 'gastroenterology'
                    },
                    {
                        text: 'Pathologie générale', value: 'general_pathology'
                    },
                    {
                        text: 'Médecine générale', value: 'physician_general_practice'
                    },
                    {
                        text: 'Chirurgie générale', value: 'general_surgery'
                    },
                    {
                        text: 'Médecine gériatrique', value: 'geriatric_medicine'
                    },
                    {
                        text: 'Gynécologie', value: 'gynecology'
                    },
                    {
                        text: 'Oncogynécologie', value: 'gynecologic_oncology'
                    },
                    {
                        text: 'Hématopathologie', value: 'hematological_pathology'
                    },
                    {
                        text: 'Hématologie', value: 'hematology'
                    },
                    {
                        text: 'Maladies infectieuses', value: 'infectious_disease'
                    },
                    {
                        text: 'Médecine interne', value: 'internal_medicine'
                    },
                    {
                        text: 'Oncologie médicale', value: 'medical_oncology'
                    },
                    {
                        text: 'Néphrologie', value: 'nephrology'
                    },
                    {
                        text: 'Neurologie', value: 'neurology'
                    },
                    {
                        text: 'Neuropathologie', value: 'neuropathology'
                    },
                    {
                        text: 'Neurochirurgie', value: 'neurosurgery'
                    },
                    {
                        text: 'Médecine nucléaire', value: 'nuclear_medicine'
                    },
                    {
                        text: 'Obstétrique et gynécologie', value: 'obstetrics_gynecology'
                    },
                    {
                        text: 'Ophtalmologie', value: 'ophthalmology'
                    },
                    {
                        text: 'Chirurgie orthopédique', value: 'orthopedic_surgery'
                    },
                    {
                        text: 'Autre', value: 'other'
                    },
                    {
                        text: 'Otolaryngologie', value: 'otolaryngology'
                    },
                    {
                        text: 'Pédiatrie', value: 'pediatrics'
                    },
                    {
                        text: 'Médecine physique et réadaptation', value: 'physicial_medicine_rehabilitation'
                    },
                    {
                        text: 'Chirurgie plastique', value: 'plastic_surgery'
                    },
                    {
                        text: 'Psychiatrie', value: 'psychiatry'
                    },
                    {
                        text: 'Radio-oncologie', value: 'radiation_oncology'
                    },
                    {
                        text: 'Radiologie', value: 'radiology'
                    },
                    {
                        text: 'Pneumologie', value: 'respirology'
                    },
                    {
                        text: 'Rhumatologie', value: 'rheumatology'
                    },
                    {
                        text: 'Médecine du Sommeil', value: 'sleep_specialist'
                    },
                    {
                        text: 'Urologie', value: 'urology'
                    },
                ]
            }
            return list[language]
        },
        allowClose(){
            let requiredFields = [
                'given_name',
                'family_name',
                'custom:preferred_language'
            ]
            let attributes = this.user.attributes
            for(let i=0; i<requiredFields.length; i++){
                let field = requiredFields[i]
                if(!attributes[field] || attributes[field].length==0){
                    return false
                }
            }
            return true
        },
        countries(){
            let countries = this.csc.countries ? this.csc.countries : []
            let output = []
            for(let i=0; i<countries.length; i++){
                let country = countries[i]
                output.push({
                    text: country.name,
                    value: country.code
                })
            }
            return output
        },
        states(){
            let states = this.csc.states ? this.csc.states : []
            if(states.length==0){
                return [
                    {
                        text: this.$store.getters.LabelController.getLabel('authentication.form.message.select_country'),
                        value: null,
                        disable: true
                    }
                ]
            }
            let output = []
            for(let i=0; i<states.length; i++){
                let state = states[i]
                output.push({
                    text: state.name,
                    value: state.code
                })
            }
            return output
        },
        userCountry(){
            return this.form['custom:country'] ? this.form['custom:country'] : null
        }
    },
    watch:{
        userCountry(){
            this.getStates()
        },
        form: {
            deep: true,
            handler(){
                let profession = this.form['custom:profession']
                let isPhysician = profession=='physician'
                if(!isPhysician){
                    this.form['custom:specialty'] = null
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled{
  background-color: $lightgray !important;
  color: $gray !important;
}
::v-deep #postal-code {
    text-transform: uppercase !important;
}
</style>