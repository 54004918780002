<template>
  <v-container fluid class="expand" :style="{'background-image':`url('${image.background}')`}">
    <v-row align="center" style="min-height: 400px;">
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"/>
        <v-col cols="12" sm="6" md="5">
            <h1><Str index="home.expand.title"/></h1>
            <p><Str index="home.expand.body"/></p>
            <!-- <v-btn><Str index="header.sign_in"/></v-btn> -->
            <StartButton class="py-6" :options="{flavor:'cta-plain'}"/>
        </v-col>
        <v-spacer v-if="$vuetify.breakpoint.lgAndUp"/>
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"/>
    </v-row>
  </v-container>
</template>

<script>
import Background from './assets/expand-background.jpg'
import StartButton from './StartButton.vue'
export default {
  components: {
    StartButton
  },
  computed: {
    image(){
      let output = {
        background: Background
      }

      return output
    }
  }
}
</script>

<style lang="scss" scoped>
.expand{
  color: white;
  border: 1px solid whitesmoke;
  background-size: cover;
}

h1{
  font-size: 4em;
  line-height: 1em;
  margin-bottom: 25px;
}

a{
  color: white !important;
  border: 3px solid white !important;
}
a:hover{
  border-color: $midgray !important;
  color: $midgray !important;
}

@media only screen and (max-width: 600px) {
h1 {
    font-size: 3.3em;
}
}
</style>