<template>
  <div style="position: fixed; bottom:5px; left: 5px; z-index: 100;">

    <v-navigation-drawer
      v-show="drawer"
      v-model="drawer"
      src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
      temporary
      dark
      width="600"
      :height="$vuetify.breakpoint.height<600 ? '600' : '800'"
      fixed
      >
      <v-list-item v-show="allow(['admin','staff','tester'])">
        <v-list-item-content>
          <v-btn dark small :to="{name:'Usage Report'}">Usage Report</v-btn>
        </v-list-item-content>
        <v-list-item-action>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-show="allow(['admin','staff','tester'])">
        <v-list-item-content>
          <v-list-item-title>Debug</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn v-if="allow(['admin','staff','tester'])" color="white" x-small fab @click="toggleDebug">
            <v-icon :color="$store.getters.debug ? 'green' : 'black'">
              {{$store.getters.debug ? 'mdi-toggle-switch-outline' : 'mdi-toggle-switch-off-outline'}}
            </v-icon>
          </v-btn>          
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-show="allow(['admin','staff','tester'])">
          <v-list-item-icon>
            <v-icon>mdi-controller-classic-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>UserController</v-list-item-title>
            <UserController/>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="allow(['admin','staff','tester'])">
          <v-list-item-icon>
            <v-icon>mdi-controller-classic-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>LabelController</v-list-item-title>
            <LabelController/>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="allow(['admin','staff','tester'])">
          <v-list-item-icon>
            <v-icon>mdi-controller-classic-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>SearchController</v-list-item-title>
            <SearchController v-if="$store.getters.LabelController"/>
          </v-list-item-content>
        </v-list-item>

        <template v-if="$store.getters.UserController">
          <v-list-item v-show="allow(['admin','staff','tester'])">
            <v-list-item-icon>
              <v-icon>mdi-controller-classic-outline</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>LanguageController</v-list-item-title>
              <LanguageController/>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item v-show="allow(['admin','staff','tester'])">
            <v-list-item-icon>
              <v-icon>mdi-controller-classic-outline</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>VideoController</v-list-item-title>
              <VideoController/>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item v-show="allow(['admin','staff','tester'])">
            <v-list-item-icon>
              <v-icon>mdi-controller-classic-outline</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>QuestionSetController</v-list-item-title>
              <QuestionSetController/>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item v-show="allow(['admin','staff','tester'])">
            <v-list-item-icon>
              <v-icon>mdi-controller-classic-outline</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>CreditController</v-list-item-title>
              <CreditController v-if="$store.getters.VideoController && $store.getters.QuestionSetController" :VideoController="$store.getters.VideoController" :QuestionSetController="$store.getters.QuestionSetController"/>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item v-show="allow(['admin','staff','tester'])">
            <v-list-item-icon>
              <v-icon>mdi-controller-classic-outline</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>CampaignController</v-list-item-title>
              <CampaignController/>
            </v-list-item-content>
          </v-list-item>
        
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-btn color="teal" dark fab x-small @click="drawer=!drawer">
      <v-icon>
        mdi-menu
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import SearchController from './SearchController.vue';
import LabelController from './LabelController.vue';
import VideoController from './VideoController.vue';
// import PledgeController from './PledgeController.vue';
import QuestionSetController from './QuestionSetController.vue';
import CreditController from './CreditController.vue';
import LanguageController from './LanguageController.vue';
import UserController from './UserController.vue';
import CampaignController from './CampaignController.vue';
export default {
    components: {
      SearchController,
      LabelController,
      VideoController,
      // PledgeController,
      QuestionSetController,
      CreditController,
      LanguageController,
      UserController,
      CampaignController
    },
    data(){
      return {
        drawer: false,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
      }
    },
    methods: {
      toggleDebug(){
        this.$store.commit('debug' , !this.$store.getters.debug)
      },
      allow(groups){
        return this.$store.getters.UserController && this.$store.getters.UserController.authorized(groups)
      }
    }
}
</script>

<style>

</style>