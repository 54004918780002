<template>
<v-app :class="['app',{'app-border':!$route.meta.print}]" style="width:100%">
    <template v-if="ready && $store.getters.siteBlocker===false">
        <AppHeader v-if="!$route.meta.print"/>
        <v-main :class="['app-main', {'custom-app-gray':$route.meta.grayBackground}]">
            <div v-if="debug" style="text-align: right;">
              Session inactivity: {{ Math.round(this.$store.getters.controllers.UserController.session.inactivity) }}<br>
              Days Left: {{ Math.round(this.$store.getters.controllers.UserController.session.time_left/60/60/24) }}<br>
              Hours Left: {{ Math.round(this.$store.getters.controllers.UserController.session.time_left/60/60) }}<br>
              Minutes Left: {{ Math.round(this.$store.getters.controllers.UserController.session.time_left/60) }}<br>
              Seconds Left: {{ Math.round(this.$store.getters.controllers.UserController.session.time_left) }}<br>
              <v-btn size="x-small" @click="$store.getters.controllers.UserController.refreshSession()">refreshSession</v-btn>
              <v-btn size="x-small" @click="$store.getters.controllers.UserController.findUser()">findUser</v-btn>
            </div>
            <router-view :language="$store.getters.LanguageController.language"/>
        </v-main>
        <AppFooter v-if="!$route.meta.print" style="margin-top: 60px; z-index: 5;"/>
    </template>
    <Controllers v-show="!$route.meta.print && ($store.getters.UserController && $store.getters.UserController.authorized(['admin','staff','tester']))"/>
    <siteBlocker/>    
</v-app>
</template>

<script>
import Vue from "vue";
import AppHeader from './components/Header/Header'
import AppFooter from './components/Footer/Footer'
import Controllers from './Controllers/Loader'
import axios from 'axios'
import siteBlocker from '@/components/siteBlocker/siteBlocker'
export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Controllers,
    siteBlocker
  },
  created(){
    let self = this
    Vue.prototype.sendRequest = self.sendRequest
  },
  data(){
    return {}
  },
  methods: {
    sendRequest: function(method='get', url, data=null, bypassUrlReplace=false){
      let self = this
      return new Promise((resolve, reject)=>{
        //&& url.indexOf('/admin/')>0
        let user = (self.$store.getters.UserController && self.$store.getters.UserController.user && self.$store.getters.UserController.user.signInUserSession) ? self.$store.getters.UserController.user : undefined
        let removeAuthHeader = url.search("https://s3")==0
        if(user && !removeAuthHeader){
          axios.defaults.headers['Authorization'] = `Bearer ${user.signInUserSession.idToken.jwtToken}`
        }else{
          delete axios.defaults.headers['Authorization']
        }
        if(process.env.NODE_ENV!='development'){
          // console.log('sendRequest',{url,data,env: process.env})
          if(bypassUrlReplace!==true){
            url = url.replace('/api',process.env.VUE_APP_API)
          }
        }
        if(method=='upload'){
          axios.defaults.headers['Content-Type'] = 'multipart/form-data'
          method = 'put'
        }else{
          delete axios.defaults.headers['Content-Type']
        }
        // console.log('sendRequest',{headers: axios.defaults.headers})
        axios({
            method,
            url,
            data
          }).then((response)=>{
            if(response.headers['content-type']=="text/calendar; charset=utf-8"){
              let filename = "meeting.ics"
              if(filename){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.setAttribute('id','download-link')
                document.body.appendChild(link);
                link.click();
                link.remove();
              }
  
            }
            resolve(response)
          },(error)=>{
            if(error.response && error.response.status!=200){
              console.error('authentication error',{error})
              reject(error)
            }
            resolve(error)
          }) 
      });
    }
  },
  computed: {
    path(){
      return this.$route.name
    },
    ready(){
      let controllers = this.$store.getters.controllers
      for(let i in controllers){
        let controller = controllers[i]
        if(controller===undefined){
          return false
        }        
      }
      console.log(this.$store.getters.controllers.UserController)
      return true
    },
    debug(){
      return this.$store.getters.debug
    }
  },
  watch:{
    path: function(after, before){
      if(after!=before){
        window.scrollTo(0,0)
      }
    },
    ready: function(){
      if(this.ready && !this.$store.getters.UserController.authenticated){
        this.sendRequest('put','/api/log',{action:'appLoad'})
      }
    }
  }
};
</script>

<style lang="scss">
html,
body {
    overflow-x: hidden !important;
}

.app-main{
  background-color: white;
}

.app{
 //max-width: 1920px;
 margin: 0 auto;
}
.app-border{
  //border: 1px solid slategray;
}
body{
  background-color: slategray;
}
.custom-app-gray{
  background-color: $lightgray !important;
}

.debug, button.debug{
  color: red !important;
  font-weight: bold;
  font-style: italic;
}

.v-btn {
    text-transform: inherit;
}

.v-card__title {
    word-break: break-word;
}
</style>
