<template>
<v-container fluid class="misc" id="testimonials-section">
    <v-row no-gutters justify="center" class="black--text" :style="{'position':'relative', 'top': $vuetify.breakpoint.width>=1200 ? '-165px' : '-111px'}">        
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"/>        
        <v-col cols="12" lg="5" md="6" class="pink-wrapper" :style="{'background-image': `url('${background.pink}')`}">
            <div class="content white--text" style="padding: 15px 41px 15px 25px;">
                <template v-if="introVideo.state==null">
                    <div style="color: white; font-size: 0.8em; width: 95%;">
                        <v-icon style="position:absolute; cursor: pointer;z-index:100;" size="100" color="white" @click="introVideo.state='initiate'">mdi-youtube</v-icon>
                        <VideoPlayer style="width:100%;" :key="`videoPlayer_${videoKey}`" class="video" :videoId="'miscVideo'" :source="video('banner-video')" :options="{video:{autoplay: true, muted: true, loop: true, playsinline: true}}"/>
                        <h4><Str index="home.misc.box.1.title"/></h4>
                        <h5><Str index="home.misc.box.1.subtitle"/></h5>
                        <p><Str index="home.misc.box.1.body"/></p>                           
                    </div>
                </template>
                <div v-if="introVideo.state!==null && deviceType=='mobile'">
                    <VideoPlayer class="videoPlayer" :videoId="'intro-Video'" :source="video(introVideoSrc)" :options="{video:{autoplay: true, loop: false, controls: true, playsinline: true}}"                       
                        @player="setIntroPlayer" @playing="introVideo.state='playing'" @ended="introVideo.state=null"/>
                </div>
            </div>
        </v-col>
        <v-col cols="12" lg="5" md="6" class="green-wrapper" :style="{'background-image': `url('${background.green}')`}">
            <div>
                <div class="content">
                    <br>
                    <h1><Str index="home.misc.box.2.title"/></h1><br>
                    <Carousel :content="carouselContent"/>
                </div>
            </div>
        </v-col>
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"/>
    </v-row>
    <div style="position: absolute" v-if="introVideo.state!==null && deviceType!=='mobile'">
        <VideoPlayer :videoId="'intro-Video'" :source="video(introVideoSrc)" :options="{video:{autoplay: true, loop: false, playsinline: true}}" 
            @player="setIntroPlayer" @playing="introVideo.state='playing'" @ended="introVideo.state=null"/>
    </div>
</v-container>
</template>

<script>
import VideoPlayer from '@/components/Videos/lib/videoPlayer.vue'
import pink from './assets/trap-pink.svg'
import green from './assets/trap-green-2.svg'
import Carousel from './Carousel.vue'
export default {
    components: {
        VideoPlayer,
        Carousel
    },
    data(){
        return {
            introVideo: {
                state: null,
                player: null
            },            
            videoKey: 0,
            deviceType: null
        }
    },
    mounted() {
        this.setDeviceType();
    },
    methods: {
        setDeviceType() {
            const platform = navigator.platform.toLowerCase();
            if (/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(platform)) {
                this.deviceType = 'mobile';
            } else if (/mac|win|linux/i.test(platform)) {
                this.deviceType = 'desktop';
            } else if (/tablet|ipad/i.test(platform)) {
                this.deviceType = 'tablet';
            } else {
                this.deviceType = 'unknown';
            }
        },
        video(param){
            if (this.language==="fr-ca"){
                return param==="banner-video" ?  "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/FRE_1080Banner_iACT_Teaser_Video_V2.mp4"
                    : param==="intro-video" ? "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/FRE_1080_iACT_Welcome_Video_V2.mp4"
                    : null
            }
            return param==="banner-video" ?  "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/ENG_1080Banner_iACT_Teaser_Video_V2.mp4"
                    : param==="intro-video" ? "https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/videos/ENG_1080_iACT_Welcome_Video_V2.mp4"
                    : null            
        },
        toggleVideoFullScreen(){
            console.log("here")
            if (this.deviceType!=='mobile'){            
                let el = document.getElementById("intro-Video");
                if (el.requestFullscreen) {
                  el.requestFullscreen();
                } else if (el.msRequestFullscreen) {
                  el.msRequestFullscreen();
                } else if (el.mozRequestFullScreen) {
                  el.mozRequestFullScreen();
                } else if (el.webkitRequestFullscreen) {
                  el.webkitRequestFullscreen();
                }

                if (document.addEventListener){
                    document.addEventListener('fullscreenchange', this.exitHandler, false)
                    document.addEventListener('mozfullscreenchange', this.exitHandler, false)
                    document.addEventListener('MSFullscreenChange', this.exitHandler, false)
                    document.addEventListener('webkitfullscreenchange', this.exitHandler, false)
                }
            }
        },
        exitHandler(){
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement){
                console.log("Exit-Full-Screen")
                this.introVideo.state = null
            }
        },
        setIntroPlayer(param){
            this.introVideo.player = param
        }        
    },
    computed: {
        background(){
            return {
                pink,
                green,
            }
        },
        carouselContent(){
            let miscTestimonials = this.$store.getters.LabelController.labels.home.misc.testimonial
            return Object.keys(miscTestimonials).map( key => 'home.misc.testimonial.'+key)
        },
        language(){
            return this.$store.getters.LanguageController.language
        },
        introVideoSrc(){
            return this.introVideo.state!==null ? 'intro-video' : null
        },
        introPlayer() {
            return this.introVideo.player
        }
    },
    watch: {
        introPlayer(after, before){
            if (after){
                return this.toggleVideoFullScreen()
            }
        },        
        language(after, before){
            this.videoKey++;
        }
    }    
}
</script>

<style lang="scss" scoped>
.pink-wrapper {
    background-size: cover;
    position: relative;
    background-clip: initial;
    z-index: 50;
    left: 80px;
    top: 0;
}
.green-wrapper {
    background-size: cover;
    position: relative;
    background-clip: initial;    
    z-index: 25;
    top: 33px;
    right: 80px;
    
    .content {
        padding: 15px 15px 15px 170px;
    }
}
@media only screen and (max-width: 1440px) {
    .pink-wrapper {
        left: 57px;
    }
    .green-wrapper { 
        right: 57px;
    }    
}
@media only screen and (max-width: 960px) {
    .pink-wrapper {
        left: 0;
        background-color: $pink;
    }
    .green-wrapper { 
        right: 0;
        top: -25px;
        background-color:$green;
        
        .content {
            padding: 10px;
        }        
    }
}
.misc{
    background-color: $lightgray-1;    
    color: white;
    font-weight: bold;
    margin-bottom: -100px;
}
h1, h2{
    line-height: 1.2em;
}
.video{
    width: 200px;
}
.content{
    margin-top: 5px; 
    padding: 20px;
    color: white;
}
</style>