import Vue from 'vue'
import VueRouter from 'vue-router'
import cache from "../plugins/cache.js";
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from '../Controllers/UserController/config.js';
Amplify.configure({
  Auth: awsconfig.Auth
});

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/sign_in',
    name: 'Sign In',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignInUp.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/sign_up',
    name: 'Sign Up',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignInUp.vue'),
    children: [
      {
        path: '/sign_up/verifyPIN',
        name: 'Verify PIN',
        meta: {
          public: true,
          allowed: '*'
        }
      },
      {
        path: '/sign_up/verify/:hash',
        name: 'Verify URL',
        meta: {
          public: true,
          allowed: '*'
        }
      }
    ],
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/privacy_policy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/terms_of_use',
    name: 'Terms of Use',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfUse.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/disclosures',
    name: 'Disclosures',
    component: () => import(/* webpackChunkName: "about" */ '../views/Disclosures.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/contact_us',
    name: 'Contact Us',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/restricted',
    name: 'Restricted',
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/educational_videos',
    name: 'Educational Videos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Videos.vue'),
    children: [
      {
        path: '/educational_videos/:video_id',
        name: 'Video',
        meta: {
          public: false,
          allowed: '*'
        }
      }
    ],
    meta: {
      public: false,
      allowed: '*'
    },
  },
  {
    path: '/invite-colleague',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "about" */ '../views/InviteColleague.vue'),
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: {
      public: false,
      allowed: '*',
      grayBackground: false
    }
  },
  {
    path: '/initial-pledge',
    name: 'Initial Pledge',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pledge.vue'),
    children: [
      {
        path: '/initial-pledge/print',
        name: 'Print Pledge',
        meta: {
          public: false,
          allowed: '*',
          questionSet_id: 1,
          print: true
        }
      }
    ],
    meta: {
      public: false,
      allowed: '*',
      questionSet_id: 1,
      grayBackground: true
    }
  },
  {
    path: '/follow-up',
    name: 'Follow Up',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pledge.vue'),
    children: [
      {
        path: '/follow-up/print',
        name: 'Print Follow-Up',
        meta: {
          public: false,
          allowed: '*',
          questionSet_id: 2,
          print: true
        }
      }
    ],
    meta: {
      public: false,
      allowed: '*',
      questionSet_id: 2,
      grayBackground: true
    }
  },
  {
    path: '/evaluation',
    name: 'Evaluation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Evaluation.vue'),
    meta: {
      public: false,
      allowed: '*',
      questionSet_id: 3
    }
  },
  {
    path: '/certificate',
    component: () => import(/* webpackChunkName: "about" */ '../views/Certificate.vue'),
    children: [
      {
        path: '/certificate/:record',
        name: 'Certificate',
        meta: {
          public: false,
          allowed: '*',
          print: true
        }
      }

    ],
    meta: {
      public: false,
      allowed: '*',
      grayBackground: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    meta: {
      public: false,
      allowed: ['staff','admin','tester']
    },
    children: [
      {
        path: '/admin/labelAdmin',
        name: 'Label Admin',
        meta: {
          public: false,
          allowed: ['staff','admin','tester']
        }
      }
    ]
  },
  {
    path: '/report',
    name: 'Usage Report',
    component: () => import(/* webpackChunkName: "about" */ '../views/UsageReport.vue'),
    meta: {
      public: false,
      allowed: ['staff','client','tester']
    }
  },
  {
    path: '/campaignAdmin',
    name: 'Campaign Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/CampaignAdmin.vue'),
    meta: {
      public: false,
      allowed: ['staff','client','tester']
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if(to.meta.public){
    next()
  }else{
    // next('/restricted')
    let findUser = async function(){
      try{
        const user = await Auth.currentAuthenticatedUser()
        let allowed = to.meta.allowed
        let groups = user.signInUserSession.accessToken.payload['cognito:groups']
        let allow = allowed.includes('*')

        if(typeof groups=='object' && groups.length!=undefined){
          for(let i=0; i<groups.length; i++){
            let group = groups[i]
            if( to.meta.allowed.includes(group) ){
              allow = true
              break;
            }
          }
        }
        // console.log('route guard',{allow, user, groups, allowed})
        if(allow){
          next()
        }else{
          next('/restricted')
        }
      }catch(err){
        console.error(err)
        cache.storage.local.set('pathAfterLogin',to.fullPath)
        next('/sign_in')
      }
    }

    findUser()
    
  }

})

export default router
