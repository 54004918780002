<template>
<div>
    <v-row v-if="controller.user===undefined && accountNotFoundError" class="custom-error mb-5 pa-3" no-gutters>
        <v-col cols="1" align-self="center">
            <v-icon color="orange" large>mdi-alert</v-icon>
        </v-col>
        <v-col align-self="center">
            <Str index="authentication.message.account_not_found"/>
        </v-col>
    </v-row>
    <v-form ref="changePassword" v-model="validForm">
        <v-text-field ref="email" v-model="form.email" autofocus :label="$store.getters.LabelController.getLabel('authentication.form.label.email')" required :rules="rules.email"/>
        
        <v-card-actions class="justify-center">
        <v-btn class="my-1" @click="requestPIN" color="blue" dark v-bind="size" tile><Str index="authentication.form.button.request_reset_pin"/></v-btn>    
        </v-card-actions>
        <v-text-field id="password_1" @keyup="checkPasswords" v-model="form.password" type="password" :label="$store.getters.LabelController.getLabel('authentication.form.label.new_password')" required :rules="rules.verifyPasswords"/>
        <v-text-field id="password_2" @keyup="checkPasswords" v-model="verifyPassword" type="password" :label="$store.getters.LabelController.getLabel('authentication.form.label.confirm_password')" required :rules="rules.verifyPasswords"/>

        <v-otp-input length="6" v-model="form.pin" type="number" required :rules="rules.otp"/>
    </v-form>
    <Str index="authentication.form.label.reset_pin"/>
    <v-card-actions class="flex-column flex-sm-row">
        <a :style="{'font-size':$vuetify.breakpoint.width<=340 ? '10px' : 'inherit'}" @click="back"><Str index="authentication.form.button.back"/></a>
        <v-spacer></v-spacer>
        <v-btn class="my-1" :disabled="!validForm" @click="resetPassword" color="blue" dark v-bind="size" tile><Str index="authentication.form.button.reset_password"/></v-btn>
    </v-card-actions>
    
    <v-snackbar v-model="ui.snackbar.display" timeout="-1">
        <Str v-if="ui.snackbar.display" :index="ui.snackbar.messageIdx"/>

        <template v-slot:action="{ attrs }">
        <v-btn class="pink-brand" text v-bind="attrs" @click="ui.snackbar.display = false">
            <Str index="authentication.form.button.close"/>
        </v-btn>
        </template>
    </v-snackbar>      
</div>
</template>
  
<script>
export default {
  name: "ChangePassword",
    created(){
        this.form.email = this.model
        this.formRules = new this.formRulesAPI(this)
    },
    data(){
        return {
            validForm: false,
            formRules: undefined,
            ui: {
                snackbar: {
                    display: false,
                    messageIdx:  null
                }
            },
            form: {
                email: null,
                password: null,
                pin: null
            },
            rememberMe: false,
            verifyPassword: null,
            rules: {
                otp: [
                    v => this.formRules.rules.answered(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field'),
                    v => this.formRules.rules.length(v,6) || this.$store.getters.LabelController.getLabel('authentication.resetPin.invalid')
                ],
                checkbox: [
                    v => this.formRules.rules.checked(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
                ],
                email: [
                    v => !!v || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field'),
                    v => this.formRules.rules.validEmail(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
                ],
                password: [
                    v => this.formRules.rules.answered(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field')
                ],
                verifyPasswords: [
                    v => this.formRules.rules.strongPassword(v) || this.$store.getters.LabelController.getLabel('authentication.form.password.weak'),
                    v => this.matchedPassword() || this.$store.getters.LabelController.getLabel('authentication.form.password.must_match')
                ]
            },
            accountNotFoundError: false,
        }
    },
    methods: {
        checkPasswords(){
            let inputs = this.$refs.changePassword.inputs
            let passwords = inputs.filter((item)=>{return ['password_1','password_2'].includes(item.id)})
            for(let i=0; i<passwords.length; i++){
                let passwordField = passwords[i]
                let v = passwordField.value
                if(this.formRules.rules.answered(v)){
                    passwordField.validate()
                }
            }
        },
        back(){            
            this.form.pin = null
            this.$emit('back')
        },
        matchedPassword(){
            return this.form.password == this.verifyPassword
        },
        async requestPIN(){
            if (this.$refs.email.validate()===false){
                return console.log(this.$refs.email.validate())
            }
            let response = await this.sendRequest('get',`/api/verification/state/${this.form.email}`)
            if (response.response!==undefined && response.response.status===404 && response.response.data==='Not Found'){
                this.ui.snackbar.messageIdx = 'authentication.message.account_not_found'
                this.ui.snackbar.display = true
                this.accountNotFoundError = true
                return
            }
            else {
                this.accountNotFoundError = false
            }
            this.form.pin = null
            response = await this.controller.forgotPassword(this.form.email)
            //console.log('resquestPIN',response)
            this.ui.snackbar.messageIdx = 'authentication.resetPin.sent'
            this.ui.snackbar.display = true

        },
        async resetPassword(){
            let email = this.form.email
            let code = this.form.pin
            let password = this.form.password
            let response = await this.controller.resetPassword(email, code, password)
            if(response=='SUCCESS'){
                this.ui.snackbar.messageIdx = 'authentication.passwordReset.success'
                this.ui.snackbar.display = true
                let self = this
                setTimeout(() => {
                  self.$emit('success',{email: email, password: password, state: 'success_reset_pwd'})
                }, 2000);
            }
            else if (this.controller.hubEvent && this.controller.hubEvent.data.code=="CodeMismatchException"){
                this.ui.snackbar.messageIdx = 'authentication.resetPin.invalid'
                this.ui.snackbar.display = true
            }
            else if (this.controller.hubEvent && this.controller.hubEvent.data.code=="ExpiredCodeException"){
                this.ui.snackbar.messageIdx = 'authentication.resetPin.expired'
                this.ui.snackbar.display = true
            }
        }
    },
    computed:{
        model(){
            return this.$attrs.value
        },
        controller(){
            return this.$store.getters.UserController
        },
        email(){
            return this.form.email
        },
        validEmail(){
            let formRules = this.formRules
            return formRules ? formRules.rules.validEmail(this.form.email) : false
        },
        size () {
            const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
            return size ? { [size]: true } : {}
        },        
    },
    watch: {
        model(){
            this.form.email = this.model
        },
        email(){
            if(this.validEmail){
                this.$emit('input',this.form.email)
            }
        }
    }
}
</script>
  
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled{
  background-color: $lightgray !important;
  color: $gray !important;
}
::v-deep .pink-brand {
  color: $pink !important;
}
.custom-error{
    background-color: black;
    color: white;
}
</style>