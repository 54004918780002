<template>
    <v-container class="acknowledgement" fluid>
        <v-row justify="center">
            <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
            <v-col style="max-width: 1060px;">
                <v-container>
                    <v-row dense>
                        <v-col cols="12" md="3" class="font-weight-light" style="font-size:22px;padding-left:8px;">
                            <Str index="home.acknowledgement.title"/>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="column">
                            <Str index="home.acknowledgement.col.1"/>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="column">
                            <Str index="home.acknowledgement.col.2"/>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="column">
                            <Str index="home.acknowledgement.col.3"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"></v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.acknowledgement{
    background-color: #565854;
    color: white;
    padding: 30px 0px 150px 0px;
}

.column{
    font-weight: bold;
}
</style>

<style lang="scss">
.acknowledgement ul{
    list-style: none;
    padding-left: 40px;
}
.acknowledgement ul li{
    line-height: 2em;
}
</style>