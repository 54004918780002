<template>
  <div v-if="ready && $store.getters.UserController.authorized(['admin','staff','tester'])">
    <v-btn depressed x-small fab @click="ui.selector=true">
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-btn depressed x-small fab @click="exposeLabels=!exposeLabels">
      <v-icon :color="exposeLabels ? 'green' : 'black'">
        {{exposeLabels ? 'mdi-toggle-switch-outline' : 'mdi-toggle-switch-off-outline'}}
      </v-icon>
    </v-btn>
    <!-- <v-btn text @click="port">Port Labels</v-btn> -->
    <v-dialog v-model="ui.selector" persistent>
      <v-card>
        <v-card-text>
          <v-text-field v-model="form.newContainer" label="New container using '.' delimiter" clearable @click:append-outer="newContainer" append-outer-icon="mdi-content-save" ></v-text-field>
          <v-expansion-panels>
            <v-expansion-panel v-for="(tree, treeKey) in labels" :key="treeKey">
              <v-expansion-panel-header>
                {{ treeKey }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Selector :tree="tree" :trace="treeKey" @editLabel="editLabel" @deleteLabel="deleteLabel"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text @click="ui.selector=false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ui.editor" persistent>
      <v-card v-if="editing">
        <v-card-title>{{ editing }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="(value, lang) in getValues()" :key="`editing-${lang}`">
                {{ lang }}
                <v-textarea outlined v-model="form.text[lang]"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
            <v-btn text @click="ui.editor=false">
              Cancel
            </v-btn>
          <v-spacer></v-spacer>
            <v-btn text @click="save">
              Save
            </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </div>
</template>

<script>
import Selector from './Label/Selector.vue'
export default {
    name: 'LabelController',
    components: {
      Selector
    },
    created: function(){
        this.initialize()
    },
    data: function(){
      return {
        ui: {
          selector: false,
          editor: false
        },
        exposeLabels: false,
        labels: undefined,
        form: {
          newContainer: null,
          text: {}
        },
        editing: null,
        ready: false,
        suffix: '_text'
      }
    },
    methods: {
      async initialize(){
        this.ready = false
        // let labels = this.cache.storage.local.get('labels')
        // if(!labels){
        //   this.save()
        // }else{
        //   this.labels = labels
        // }
        let response = await this.sendRequest('get','/api/labels')
        this.labels = response.data
        this.$store.commit('LabelController', this)
        this.ready = true
      },
      changeMode(){
        this.exposeLabels = !this.exposeLabels
      },
      selector: function(value){
        this.ui.selector = value ? value : !this.ui.selector
      },
      getLabel(index, lang){
          let labels = this.labels
          let language = lang ? lang : this.$store.getters.LanguageController.language
          let array = index.split('.')
          let target
          let label
          for(let i=0; i<array.length; i++){
              let key = array[i]
              if(i==0){
                  target = ''
                  target += `['${key}']`
              }else{
                  target += `['${key}']`
              }
              label = eval(`labels${target}`)
              if(!label){
                  return undefined
                  break;
              }
          }

          target += `._text`
          label = eval(`labels${target}`)
          if(label && label[language]){
            return label[language]
          }else{
            return undefined
          }

      },
      editLabel(target){
        this.editing = target
        this.ui.editor = true
      },
      deleteLabel(target){
        let key = target.replaceAll('._text','')
        if(confirm(`Delete: ${key}?`)){
          let array = key.split('.')
          let target = ''
          for(let i=0; i<array.length; i++){
              let key = array[i]
              target += `['${key}']`
          }
          eval(`delete this.labels${target}`)
          let self = this
          self.ready = false
          setTimeout(() => {
            self.save()
          }, 800);
        }
      },
      stopEdit(){
        this.editing = null
        this.ui.editor = false
        this.$router.push({query:{}})
      },
      getValues(index){
        let editing = index ? index : this.editing
        let labels = this.labels
        if(editing){
          let array = editing.split('.')
          let target = ''
          let label
          for(let i=0; i<array.length; i++){
              let key = array[i]
              target += `['${key}']`
              label = eval(`labels${target}`)
              if(!label){
                  return undefined
                  break;
              }
          }
          
          if(!index){
            for(let key in label){
              this.form.text[key] = label[key]
            }
          }
          return label
        }
        return undefined
      },
      newContainer(){
        let labels = this.labels
        let index = this.form.newContainer
        if(index!=null && index.length>0){
          let array = index.split('.')
          
          let next = ''
          let prev = ''
          for(let i=0; i<array.length; i++){
            let value = array[i]
            next += `['${value}']`
            let label = eval(`labels${next}`)
            if(!label){
              eval(`this.$set(labels${prev},value,{})`)
            }
            prev += `['${value}']`
  
            if(i==array.length-1){
              eval(`this.$set(labels${next},'_text',{'en-ca':null,'fr-ca':null})`)
            }
          }
          this.form.newContainer = null
          this.$forceUpdate()
        }
      },
      async save(){
        let suffix = this.suffix
        let label = this.getValues(this.editing)
        let form = this.form.text
        let key = this.editing.replaceAll(`.${suffix}`,'')
        for(let lang in form){
          label[lang] = form[lang]
        }
        // this.cache.storage.local.set('labels',this.labels)
        let packet = {key, label: form, suffix}
        let response = await this.sendRequest('patch','/api/labels', packet)
        
        if(response.status==200){
            this.$forceUpdate()
            this.stopEdit()
            this.initialize()
        }
      },
      async port(){
        let self = this
        let suffix = this.suffix
        async function process(data, trace){
            for(let key in data){
                let path = trace ? `${trace}.${key}` : key
                let record = data[key]
                
                if(key==suffix){
                    let label = record
                    let packet = {key: path.replaceAll(`.${suffix}`,''), label, suffix}
                    let response = await self.sendRequest('patch','/api/labels', packet)
                    console.log('writing',{
                        packet,
                        response
                    })
                }
                if(key!=suffix){
                    process(record, path)
                }
            }
        }
        let labels = JSON.stringify(this.labels)
        labels = JSON.parse(labels)
        process(labels)
      },
      async test(){
        let result = await this.sendRequest('get','/api/labels')
        console.log('test',result)
      }
    },
    computed: {
      queryIndex(){
        if(this.$route.name=='Label Admin'){
          return this.$route.query.index
        }
        return undefined
      }
    },
    watch: {
      queryIndex: function(){
        let index = this.queryIndex
        if(index){
          let label = this.getLabel(index)
          if(!label){
            this.form.newContainer = index
            this.newContainer()
          }
          let self = this
          self.selector(true)
          setTimeout(() => {
            self.editLabel(`${index}._text`)            
          }, 800);
        }
      }
    }
}
</script>

<style>

</style>